import { Capacitor } from '@capacitor/core'
import axios from 'axios'

import { ApiClient } from '@/generated/api/ApiClient'
import { LOGIN } from '@/modules/auth/routes'
import router from '@/router'

import i18n from './i18n'

let client: ApiClient | null = null
export const useApiClient = () => {
  if (!client) {
    client = new ApiClient({
      BASE: Capacitor.isNativePlatform() ? import.meta.env.VITE_BASE_URL : '',
      HEADERS: async (): Promise<Record<string, string>> => {
        return { 'Accept-Language': i18n.global.locale.value }
      },
    })
  }
  return client
}

axios.interceptors.response.use(
  (response) => response, // Simply return the response if successful
  async (error) => {
    // Check if we've received a 401-response status
    if ([401].includes(error.response?.status)) {
      await router.push({ name: LOGIN })
    } else if (error.response?.status >= 500) {
      await router.push('/500')
    }

    // Ensure the rejection reason is an instance of Error
    if (!(error instanceof Error)) {
      error = new Error(error)
    }

    // Always reject the promise if there was an error
    return Promise.reject(error)
  }
)
