<template>
  <footer class="h-[172px] mx-5 flex flex-col items-center flex-shrink-0 text-center">
    <SvgIcon name="logo" :size="32" class="text-white-20 mt-10" />
    <p class="mt-5 text-white-40 whitespace-normal">
      {{ $t('common.copyright', { currentDate: dayjs().format('YYYY') }) }}
      <span class="whitespace-nowrap"> {{ $t('common.all-rights-reserved') }} </span>
    </p>
    <Link
      icon=""
      class="mt-1 text-white-60"
      target="_blank"
      download="terms_of_use.pdf"
      :href="termsOfUseDocumentUrl"
      >{{ $t('common.terms-and-conditions') }}</Link
    >
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import Link from '@/components/common/link/Link.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import dayjs from '@/dayjs'
import { useAuthStore } from '@/modules/auth/store'

const termsOfUseDocumentUrl = ref()

const authStore = useAuthStore()

onMounted(async () => {
  try {
    termsOfUseDocumentUrl.value = (await authStore.getTermsOfUseDocumentUrl()).document
  } catch (e) {
    console.log('Error while fetching terms of use')
  }
})
</script>
